import "./App.css";
import { Helmet } from "react-helmet";
import logo from "./images/logo_concept_001_white.svg";

function App() {
	return (
		<div className="wrapper">
			<div className="contentWrapper">
				<div className="container">
					<Helmet>
						<title>melismatic.io</title>

						<meta
							name="description"
							content="Building the world's most musical AI."
						/>
						<link rel="icon" href="/favicon.ico" />
						<meta name="og:title" content="melismatic.io" />
					</Helmet>
					<header className="header">
						<img
							src={logo}
							className="icon"
							height={134}
							width={134}
							alt="logo"
						/>
						<h1 className="heading2Xl">
							MELISMATIC<span className="punct">.</span>
						</h1>
					</header>
					<main>
						<section className="tagWrapper">
							<span className="tag">
								Building the world&apos;s most musical AI.
								<br />
								<br />
								<a className="ctaButton" href="mailto: hello@melismatic.io">
									Say Hello
								</a>
							</span>
						</section>
					</main>
				</div>
			</div>
			<div className="coverUp"></div>
		</div>
	);
}

export default App;
// TODO Define head title
